export default {
  init() {
    // JavaScript to be fired on the about us page
  },
  finalize() {
    const accordionsItems = document.querySelectorAll(".accordion-team");
    const collapseElementList = [].slice.call(document.querySelectorAll('#all-team .collapse'));
    const employeeItems = document.querySelectorAll(".team-item");
    const closeAllBtn = document.getElementById("close-all-btn");
    const openAllBtn = document.getElementById("open-all-btn");
    const alphabeticalBtn = document.getElementById("alphabetical-btn");
    const officesAGM = document.getElementById("officesAGM");
    const chargesAGM = document.getElementById("chargesAGM");
    const servicesAGM = document.getElementById("servicesAGM");
    const employeesFilter = document.getElementById("employeesFilter");

    if (accordionsItems !== null && employeeItems !== null && officesAGM !== null && chargesAGM !== null && servicesAGM !== null && employeesFilter !== null) {
      // Función para aplicar los filtros
      const applyFilters = () => {
        const selectedService = servicesAGM.value;
        const selectedOffice = officesAGM.value;
        const selectedCharge = chargesAGM.value;
        const selectedEmployee = employeesFilter.value;

        // Ocultar todas las cards inicialmente
        for (const employeeItem of employeeItems) {
          employeeItem.classList.add("d-none");
        }

        // Ocultar todos los títulos de los grupos
        for (const accordionsItem of accordionsItems) {
          accordionsItem.classList.add("d-none");
        }

        // Filtrar las cards según los valores seleccionados
        let filteredElements = employeeItems;

        // Si hay un empleado seleccionado, ignorar los otros filtros
        if (selectedEmployee !== 'none') {
          filteredElements = Array.from(filteredElements).filter(element => 
            element.dataset.title === selectedEmployee
          );
        } else {
          // Filtrar por servicio (basado en el id del grupo)
          if (selectedService !== 'none') {
            const serviceGroup = document.getElementById(selectedService);
            if (serviceGroup) {
              filteredElements = Array.from(filteredElements).filter(element => 
                element.closest('.accordion-team').id === selectedService
              );
            }
          }

          // Filtrar por oficina
          if (selectedOffice !== 'none') {
            filteredElements = Array.from(filteredElements).filter(element => 
              element.dataset.offices.includes(selectedOffice)
            );
          }

          // Filtrar por cargo
          if (selectedCharge !== 'none') {
            filteredElements = Array.from(filteredElements).filter(element => 
              element.dataset.charges === selectedCharge
            );
          }
        }

        // Mostrar las cards que coinciden con los filtros
        for (const filteredElement of filteredElements) {
          filteredElement.classList.remove("d-none");

          // Mostrar el título del grupo al que pertenece la card
          const groupTitle = filteredElement.closest('.accordion-team');
          if (groupTitle) {
            groupTitle.classList.remove("d-none");
          }
        }
      };

      // AGM Employee
      employeesFilter.addEventListener('input', () => {
        // Resetear los otros selects
        servicesAGM.value = 'none';
        officesAGM.value = 'none';
        chargesAGM.value = 'none';

        // Aplicar filtros
        applyFilters();
      });

      // AGM Service
      servicesAGM.addEventListener('input', () => {
        // Resetear el selector de empleados
        employeesFilter.value = 'none';

        // Aplicar filtros
        applyFilters();
      });

      // AGM Offices
      officesAGM.addEventListener('input', () => {
        // Resetear el selector de empleados
        employeesFilter.value = 'none';

        // Aplicar filtros
        applyFilters();
      });

      // AGM Charges
      chargesAGM.addEventListener('input', () => {
        // Resetear el selector de empleados
        employeesFilter.value = 'none';

        // Aplicar filtros
        applyFilters();
      });
    }

    if (accordionsItems !== null && closeAllBtn !== null) {
      closeAllBtn.style.cursor = "pointer";
      closeAllBtn.addEventListener('click', function () {
        this.classList.add("d-none");
        openAllBtn.classList.remove("d-none");
        for (const collapseElement of collapseElementList) {
          collapseElement.classList.add("collapsing");
          collapseElement.classList.remove("show");
          collapseElement.previousElementSibling.getElementsByClassName('accordion-button')[0].classList.add("collapsed");
        }
      });
    }

    if (accordionsItems !== null && openAllBtn !== null) {
      openAllBtn.style.cursor = "pointer";
      openAllBtn.addEventListener('click', function () {
        this.classList.add("d-none");
        closeAllBtn.classList.remove("d-none");
        for (const collapseElement of collapseElementList) {
          collapseElement.classList.remove("collapsing");
          collapseElement.classList.add("show");
          collapseElement.previousElementSibling.getElementsByClassName('accordion-button')[0].classList.remove("collapsed");
        }
      });
    }

    if (alphabeticalBtn !== null) {
      const lista = document.getElementById("alphabetical-gallery");
      const elementos = Array.from(lista.children);
      const select = document.getElementById("alphabeticalOrder");

      window.addEventListener('DOMContentLoaded', (event) => {
        elementos.sort((a, b) => {
          const idA = a.id.toUpperCase();
          const idB = b.id.toUpperCase();
          if (idA < idB) {
            return -1;
          }
          if (idA > idB) {
            return 1;
          }
          return 0;
        });

        lista.innerHTML = '';

        for (const elemento of elementos) {
          lista.appendChild(elemento);
        }
      });

      select.addEventListener("change", () => {
        const sortOrder = select.value;

        elementos.sort((a, b) => {
          const idA = a.getAttribute("id").toLowerCase();
          const idB = b.getAttribute("id").toLowerCase();

          if (sortOrder === "alphabeticalOrderSelectAZ") {
            return idA.localeCompare(idB);
          }
          if (sortOrder === "alphabeticalOrderSelectZA") {
            return idB.localeCompare(idA);
          }
        });

        for (const elemento of elementos) {
          lista.appendChild(elemento);
        }
      });
    }
  }
};