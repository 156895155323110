/* global my_ajax_object */
import FsLightbox from 'fslightbox';

export default {
  init() {
    // JavaScript to be fired on the about us page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    const allEntriesPostsContainer = document.getElementById('all-entries-posts-container');
    const filteredPostsContainer = document.getElementById('filtered-posts-news-container');

    // Pages Controllers
    document.addEventListener('DOMContentLoaded', function () {
      const pageSelect = document.getElementById('pagesNewsAGM');

      if ( pageSelect !== null )  {
        const homeURL = pageSelect.getAttribute('data-home-url');

        pageSelect.addEventListener('change', function () {
            if (pageSelect.value !== '') {
              const selectedPageID = pageSelect.value;
              const pageURL = `${homeURL}?page_id=${selectedPageID}`;
              window.location.href = pageURL;
            }
        });
      }
    });

    // Services Controllers
    document.addEventListener('DOMContentLoaded', function () {
      // Seleccionar elementos del DOM
      const form = document.getElementById('form-filter');
      const taxonomySelect = document.getElementById('servicesNewsAGM');
      const allEntriesPostsContainer = document.getElementById('all-entries-posts-container');
      const filteredPostsContainer = document.getElementById('filtered-posts-news-container');
    
      if (form && taxonomySelect && allEntriesPostsContainer && filteredPostsContainer) {
        const allowedPostTypes = form.getAttribute('data-post-type-names'); // Ej: "video, podcast"
    
        taxonomySelect.addEventListener('change', function () {
          const selectedTermId = taxonomySelect.value;
    
          if (selectedTermId) {
            // Resetear otros selects si es necesario
            const employeesSelect = document.getElementById("employeesNewsFilter");
            const datesSelect = document.getElementById("datesAGM");
            if (employeesSelect) employeesSelect.value = 'none';
            if (datesSelect) datesSelect.value = 'none';
    
            // Construir la URL AJAX utilizando la URL y el idioma desde el objeto localizado
            let ajaxUrl = my_ajax_object.ajax_url + '?action=get_posts_by_taxonomy';
            ajaxUrl += '&id=' + encodeURIComponent(selectedTermId);
            ajaxUrl += '&lang=' + encodeURIComponent(my_ajax_object.lang);  // <== Pasar el idioma
            if (allowedPostTypes) {
              ajaxUrl += '&allowed_post_types=' + encodeURIComponent(allowedPostTypes);
            }
            console.log("ajaxUrl:", ajaxUrl);
    
            // Realizar la petición AJAX
            const xhr = new XMLHttpRequest();
            xhr.open('GET', ajaxUrl, true);
            xhr.onload = function () {
              if (xhr.status === 200) {
                allEntriesPostsContainer.classList.add("d-none");
                filteredPostsContainer.innerHTML = xhr.responseText;
              } else {
                console.error('Error en la solicitud AJAX');
              }
            };
            xhr.send();
          } else {
            filteredPostsContainer.innerHTML = '';
          }
        });
      }
    });    
          
    // Authors Controllers
    document.addEventListener('DOMContentLoaded', function() {
      const authorSelect = document.getElementById('employeesNewsFilter');
      const form = document.getElementById('form-filter');
      const allEntriesPostsContainer = document.getElementById('all-entries-posts-container');
      const filteredPostsContainer = document.getElementById('filtered-posts-news-container');
    
      if (authorSelect && form && allEntriesPostsContainer && filteredPostsContainer) {
        authorSelect.addEventListener('change', function() {
          // Resetea otros selects
          const taxonomySelect = document.getElementById("servicesNewsAGM");
          const datesSelect = document.getElementById("datesAGM");
          if (taxonomySelect) taxonomySelect.value = 'none';
          if (datesSelect) datesSelect.value = 'none';
    
          let selectedValue = authorSelect.value;
    
          // Obtener el atributo data-post-type-names del formulario
          const allowedPostTypes = form.getAttribute('data-post-type-names'); // Ej: "video, podcast"
    
          // Construir la cadena de datos para enviar
          let data = 'action=filter_posts_by_agm_author&selected_value=' + encodeURIComponent(selectedValue);
          if (allowedPostTypes) {
            data += '&allowed_post_types=' + encodeURIComponent(allowedPostTypes);
          }

          console.log("ajaxUrl:", data);
    
          // Realizar la solicitud AJAX vía POST
          const xhr = new XMLHttpRequest();
          xhr.open('POST', '/wp-admin/admin-ajax.php', true);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 200) {
              allEntriesPostsContainer.classList.add("d-none");
              filteredPostsContainer.innerHTML = xhr.responseText;
            }
          };
          xhr.send(data);
        });
      }
    });

    // Dates Controllers
    document.addEventListener('DOMContentLoaded', function () {
      // Seleccionar elementos clave
      const form = document.getElementById('form-filter');
      const monthSelect = document.getElementById('datesAGM');
      const allEntriesPostsContainer = document.getElementById('all-entries-posts-container');
      const filteredPostsContainer = document.getElementById('filtered-posts-news-container');
    
      if (form && monthSelect && allEntriesPostsContainer && filteredPostsContainer) {
        monthSelect.addEventListener('change', function () {
          const selectedOption = monthSelect.value;
    
          // Si se selecciona la opción "none", recargar la página o limpiar resultados
          if (selectedOption === 'none') {
            window.location.reload();
            return;
          }
    
          // Resetea otros selects (opcional)
          const taxonomySelect = document.getElementById("servicesNewsAGM");
          const employeesSelect = document.getElementById("employeesNewsFilter");
          if (taxonomySelect) taxonomySelect.value = 'none';
          if (employeesSelect) employeesSelect.value = 'none';
    
          // Obtener los allowed post types del atributo del formulario
          const allowedPostTypes = form.getAttribute('data-post-type-names'); // Ej: "video, podcast"
          
          // Construir la cadena de datos para enviar vía POST
          let data = 'action=get_posts_by_month&month_year=' + encodeURIComponent(selectedOption);
          if (allowedPostTypes) {
            data += '&allowed_post_types=' + encodeURIComponent(allowedPostTypes);
          }
          console.log("Datos enviados:", data);
    
          // Crear y enviar la solicitud AJAX
          const xhr = new XMLHttpRequest();
          xhr.open('POST', '/wp-admin/admin-ajax.php', true);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
              allEntriesPostsContainer.classList.add("d-none");
              filteredPostsContainer.innerHTML = xhr.responseText;
            }
          };
          xhr.send(data);
        });
      }
    });    
  }
};
